import React, { useEffect } from "react";
import { useLocation } from "@reach/router";
import { parse } from "query-string";
import {
  useRequestPassResetMutation,
  useCurrentUserQuery,
} from "../features/user/userApiSlice";
import toast from "react-hot-toast";
import { navigate } from "gatsby-link";
import { useForm, SubmitHandler } from "react-hook-form";
import { handleErrorResponse } from "../app/apiSlice";
import Layout from "../components/Layout";
import FormPageWrapper from "../components/FormPageWrapper";
import ButtonPrimary from "../components/ButtonPrimary";
import ButtonSpinner from "../components/ButtonSpinner";
import Seo from "../features/seo/Seo";
import { HeadProps } from "gatsby";
import * as Sentry from "@sentry/gatsby";

interface RequestPassResetFormValues {
  email: string;
}

const RequestPassReset = () => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<RequestPassResetFormValues>();

  // Get the link_invalid flag.
  const location = useLocation();
  const queryParams = parse(location.search);
  const isLinkInvalidQueryParam = Number(queryParams["link_invalid"]);
  useEffect(() => {
    if (isLinkInvalidQueryParam === 1) {
      toast.error(
        "You have tried to use a one-time login link that has expired or is invalid. Please request a new one using the form below."
      );
    }
  }, [isLinkInvalidQueryParam]);

  // Check if the user is already authenticated.
  const { data: currentUser } = useCurrentUserQuery();
  useEffect(() => {
    if (currentUser?.is_authenticated) {
      toast.error(
        "You are already signed in. Change your password here instead."
      );
      navigate("/user/change-password");
    }
  }, [currentUser?.is_authenticated]);

  const [trigger, { isLoading }] = useRequestPassResetMutation();

  const onSubmit: SubmitHandler<RequestPassResetFormValues> = (values) => {
    trigger({ email: values.email })
      .unwrap()
      .then((fulfilled) => {
        toast.success(
          `You will receive a password reset email at ${values.email} in a moment if your email is currently in our system. Check your spam folder if it doesn't land in your inbox.`,
          { duration: 12000 }
        );
        navigate("/");
      })
      .catch((rejected) => {
        handleErrorResponse(rejected).forEach((m) => {
          const e = Error(m);
          e.name = "request-password-reset: onSubmit";
          Sentry.captureException(e);
        });
      });
  };

  return (
    <Layout>
      <FormPageWrapper title="Reset your password">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="mb-5">
            <label
              className="font-bold text-neutral-700 block mb-1"
              htmlFor="email"
            >
              Email
            </label>
            <input
              id="email"
              className={`border border-neutral-300 shadow p-3 w-full rounded`}
              placeholder="email@example.com"
              type="email"
              size={60}
              {...register("email", {
                required: true,
              })}
            />
            {errors?.email && (
              <p className="text-sm text-red-400 mt-1">
                This field is required
              </p>
            )}
            <p className="mt-1 text-neutral-700">
              A password reset link will be sent to your registered email
              address.
            </p>
          </div>
          <ButtonPrimary size="20" disabled={isLoading}>
            Submit
            <ButtonSpinner enabled={isLoading} />
          </ButtonPrimary>
        </form>
      </FormPageWrapper>
    </Layout>
  );
};

export default RequestPassReset;

export const Head = (props: HeadProps) => {
  const title = "Request password reset | Giancoli Answers";

  return <Seo title={title} pathname={props.location.pathname} />;
};
